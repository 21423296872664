import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {chain, map, pipe, prepend, prop, uniq} from 'ramda';

import useStoriesData from '../queries/useStoriesData';
import {colors, devices} from '../styles/theme';
import {NavigationContext} from '../services/navigation';
import {GridComponentContainer, Subtitle} from '../components/atoms';
import {StoryPreview} from '../components/organisms/blocks';
import {PageContent} from '../components/organisms/Layout';
import {StoriesFilter} from '../components/molecules';
import {Header, Intro, SEO} from '../components/organisms';

const ThumbnailsContainer = styled(GridComponentContainer)`
  display: grid;
  grid-template-columns: 1fr;
  @media ${devices.tabletPortrait} {
    grid-template-columns: repeat(4, 1fr);
  }
  @media ${devices.tabletLandscape} {
    grid-template-columns: repeat(6, 1fr);
  }
`;

const ShowFilter = styled(Subtitle)`
  grid-column: 1 / -1;
  padding-left: 16px;
  font-size: 16px;
  color: ${colors.lightBlue};
  cursor: pointer;
  display: block;
  @media ${devices.tabletPortrait} {
    display: none;
  }
`;

const Stories = () => {

  const {setLocation} = React.useContext(NavigationContext);
  useEffect(() => {
    setLocation(window.location.pathname);
  }, []);

  const {storyOverview, stories} = useStoriesData();
  const {featured, header, intro, featuredStartingColumn} = storyOverview || {};
  const getSingleStoryTags = pipe(prop('tags'), map(prop('tag')));
  const getMultipleStoryTags = chain(getSingleStoryTags);
  const getFilterOptions = pipe(getMultipleStoryTags, prepend('All'), uniq);
  const [checked, setChecked] = useState('All');
  const [showFilter, setShowFilter] = useState(false);

  return (
    <PageContent>
      <SEO
        title={storyOverview?.SEO?.title}
        description={storyOverview?.SEO?.description}
        meta={[
          {
            name: 'keywords',
            content: storyOverview?.SEO?.keywords,
          },
          {
            property: 'og:image',
            content: `${process.env.GATSBY_CLIENT_ORIGIN}${header?.media?.file?.file?.publicURL}`
          }
        ]}
      />
      <Header header={header}/>
      <Intro intro={intro} />
      {featured && <StoryPreview
        story={featured}
        featured
        featuredStartingColumn={featuredStartingColumn}
      />}
      <ShowFilter onClick={() => setShowFilter(!showFilter)}>
        {`${showFilter ? 'Hide' : 'Show'} filters`}
      </ShowFilter>
      <StoriesFilter
        name="story"
        options={getFilterOptions(stories)}
        checked={checked}
        setChecked={setChecked}
        show={showFilter}
      />
      <ThumbnailsContainer fromColumn={2} widthInColumns={6}>
        {stories
        .filter(story => (getSingleStoryTags(story).includes(checked) || checked === 'All'))
        .map(story => <StoryPreview key={story.id} story={story} />)}
      </ThumbnailsContainer>
    </PageContent>
  );
};

export default Stories;
