import {graphql, useStaticQuery} from 'gatsby';

const useStoriesData = () => {
  const data = useStaticQuery(graphql`
  query {
    strapi {
      storyOverview {
        SEO {
          ...SEO
        }
        featured {
          ...Story
        }
        header {
          ...RegularHeader
        }
        intro {
          ...Intro
        }
        featuredStartingColumn
      }
      stories (sort: "created_at:desc") {
        ...Story
      }
    }
  }
`);

  return ({
    ...data.strapi,
  });
};

export default useStoriesData;
